<template>
    <!-- Full size variant -->
    <div
        v-if="variant === 'full'"
        :class="[
            'nav-item-full',
            selected ? 'selected' : '',
            ident ? 'ident' : '',
        ]"
        @click="$emit('click')"
    >
        <div class="icon-full">
            <component :is="icon" />
        </div>
        <span>{{ name }}</span>
    </div>

    <!-- Compacted size variant -->
    <div v-else>
        <div
            :id="itemId"
            :class="['nav-item-short', selected ? 'selected' : '']"
            @click="$emit('click')"
        >
            <div class="icon-short">
                <component :is="icon" />
            </div>
        </div>
        <Tooltip
            :target="itemId"
            :text="name"
            :placement="tooltipPlacement"
        />
    </div>
</template>

<script>
import Tooltip from "./Tooltip.vue";
export default {
    components: { Tooltip },
    props: {
        itemId: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Object,
        },
        ident: {
            type: Boolean,
            default: false,
        },
        tooltipPlacement: {
            type: String,
            default: "right",
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-full {
    width: 14px;
    height: 14px;
    svg {
        transform: translate(-20%, -10%) scale(0.5833);
        fill: #998f8a;
    }
}

.icon-short {
    width: 20px;
    height: 20px;
    svg {
        transform: scale(0.8335);
        fill: #998f8a;
    }
}

.nav-item-full {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 10px;
    line-height: 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    align-self: stretch;

    cursor: pointer;
    user-select: none;

    &.ident {
        gap: 3.5em;
    }

    span {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex: 1;
        color: #998f8a;

        font-style: normal;
        font-weight: 400;
    }

    &:hover {
        background-color: #ffede2;
        svg {
            fill: #974900;
        }
        span {
            color: #974900;
        }
    }

    &:active {
        background-color: #ffdbc4;
        color: #974900;
    }

    &.selected {
        background-color: #974900;
        svg {
            fill: #fff !important;
        }
        span {
            color: #fff;
        }
    }
}

.nav-item-short {
    padding: 6px 12px;
    border-radius: 5px;
    max-width: max-content;

    cursor: pointer;
    border: 1px solid transparent;

    min-width: 48px;
    height: 48px;

    display: flex;
    align-items: center;

    user-select: none;
    margin: 0 auto;

    &:hover {
        background: #ffede2;
    }

    &:active {
        background: #ffdbc4;
    }

    &.selected {
        background-color: #974900;
        svg {
            fill: #fff !important;
        }
    }
}
</style>
