var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"sidebar",class:[_vm.isOpen ? 'open' : '', _vm.wide ? 'wide' : ''],attrs:{"id":"menu-bar"}},[_c('div',{class:['logo-container', _vm.isOpen ? 'logo-full' : 'logo']},[(_vm.isOpen)?_c('LogoFull',{staticClass:"logo-full-icon"}):_c('LogoShort',{staticClass:"logo-short-icon"}),(_vm.wide)?_c('button',{staticClass:"menu-full-close-btn",on:{"click":function($event){return _vm.$emit('close')}}},[_c('CloseIcon',{staticClass:"close-icon",style:({
                        width: '12px !important',
                        height: '12px !important',
                    })})],1):_vm._e()],1),_c('div',{staticClass:"user-info"},[_c('Profile',{attrs:{"variant":_vm.variant,"user":_vm.user,"title":_vm.$t('Menu.Titles.Account')},on:{"close":function($event){return _vm.$emit('close')},"logout":function($event){return _vm.$emit('logout')}}})],1),_c('NavItemGroup',{ref:"generalGroup",attrs:{"title":_vm.$t('Menu.Groups.General'),"variant":_vm.variant}},[_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'home'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'home'\n                          )\n                "}],attrs:{"name":_vm.$t('Menu.Items.MyInd'),"variant":_vm.variant,"selected":_vm.currentPage.includes('myind'),"icon":_vm.icons.MyIndIcon,"item-id":"myind-sidebar","data-redirect":"home"},on:{"click":function () { return _vm.redirect('myind'); }}}),_c('LanguageSelector',{attrs:{"variant":_vm.variant}})],1),_c('NavItemGroup',{ref:"funcionalitiesGroup",attrs:{"title":_vm.$t('Menu.Groups.Funcionalities'),"variant":_vm.variant}},[_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'operator'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'operator'\n                          )\n                "}],attrs:{"name":_vm.operatorMode ? _vm.$t('PCP Mode') : _vm.$t('Operator Mode'),"variant":_vm.variant,"icon":_vm.operatorMode ? _vm.icons.PCPMode : _vm.icons.Operator,"data-redirect":"operator"},on:{"click":function () { return _vm.toggleOperator(); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(function (item) { return [
                                  'operator-orders-all',
                                  'order-list' ].includes(item.id); }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find((item) =>\n                              [\n                                  'operator-orders-all',\n                                  'order-list',\n                              ].includes(item.id)\n                          )\n                "}],attrs:{"name":_vm.$t('Orders'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/order'),"icon":_vm.icons.TotalOrders,"data-redirect":_vm.operatorMode ? 'operator-orders-all' : 'order-list'},on:{"click":function () { return _vm.redirect(
                            _vm.operatorMode
                                ? 'operator-orders-all'
                                : 'order-list'
                        ); }}}),(_vm.operatorMode)?_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'operator-barcode'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'operator-barcode'\n                          )\n                "}],attrs:{"name":_vm.$t('Operator.BarCodeTitle'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/barcode'),"icon":_vm.icons.BarcodeIcon,"data-redirect":"operator-barcode"},on:{"click":function () { return _vm.redirect('operator-barcode'); }}}):_vm._e(),(!_vm.operatorMode)?_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'production-progress'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'production-progress'\n                          )\n                "}],attrs:{"name":_vm.$t('Menu.Items.Appointments'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/production-progress'),"icon":_vm.icons.OperationProgress,"data-redirect":"production-progress"},on:{"click":function () { return _vm.redirect('production-progress'); }}}):_vm._e(),(!_vm.operatorMode)?_c('CollapseNavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'dashboards'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'dashboards'\n                          )\n                "}],attrs:{"name":_vm.$t('Dashboards'),"items":_vm.dashboardItems,"variant":_vm.variant,"icon":_vm.icons.DashboardIcon,"data-redirect":"dashboards"}}):_vm._e(),(!_vm.operatorMode)?_c('CollapseNavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'settings'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'settings'\n                          )\n                "}],attrs:{"name":_vm.$t('Settings'),"items":_vm.settingsItems,"variant":_vm.variant,"icon":_vm.icons.SettingsIcon,"data-redirect":"settings"}}):_vm._e()],1),_c('NavItemGroup',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.isOpen
                    ? true
                    : !_vm.overflowNavItems.find(function (item) { return item.id == 'support'; })
            ),expression:"\n                isOpen\n                    ? true\n                    : !overflowNavItems.find((item) => item.id == 'support')\n            "}],ref:"helpGroup",attrs:{"title":_vm.$t('Menu.Groups.Help'),"border":false,"variant":_vm.variant}},[_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.isOpen
                        ? true
                        : !_vm.overflowNavItems.find(
                              function (item) { return item.id == 'support'; }
                          )
                ),expression:"\n                    isOpen\n                        ? true\n                        : !overflowNavItems.find(\n                              (item) => item.id == 'support'\n                          )\n                "}],attrs:{"name":_vm.$t('Menu.Items.Support'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/support'),"icon":_vm.icons.HeadsetIcon,"data-redirect":"support"},on:{"click":function () { return _vm.redirect('support'); }}})],1),_c('Extra',{directives:[{name:"show",rawName:"v-show",value:(_vm.overflowNavItems.length),expression:"overflowNavItems.length"}],attrs:{"items":_vm.overflowNavItems,"variant":_vm.variant}}),_c('SiteSelector',{attrs:{"variant":_vm.variant,"device":_vm.device,"origin":"sidebar"}})],1),(!_vm.wide)?_c('button',{staticClass:"toggle-sidebar",on:{"click":_vm.toggleSidebar}},[(_vm.isOpen)?_c('span',[_c('ArrowLeft',{staticClass:"toggle-icon"})],1):_c('span',[_c('ArrowRight',{staticClass:"toggle-icon"})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }