<template>
    <b-popover
        :custom-class="`navigation_popover ${customPosition}`"
        :triggers="localTriggers"
        :target="target"
        :placement="placement"
        :offset="offset"
        :show.sync="show"
        boundary="viewport"
        @hide="hidePopover"
    >
        <header v-if="title">{{ title }}</header>
        <div v-for="(item, index) in items">
            <button
                :key="`menu-item-${index}`"
                :id="`menu-item-${index}`"
                @click="handleItemClick(item)"
            >
                <div class="button-content">
                    <div class="icon" v-if="item.icon">
                        <component :is="item.icon" />
                    </div>
                    {{ $t(item.title) }}
                </div>
                <div
                    v-if="item.items"
                    :class="['icon-dropdown', open ? 'flipped' : '']"
                >
                    <ArrowDown />
                </div>
            </button>

            <b-popover
                custom-class="navigation_popover child"
                v-if="item.items"
                :show.sync="open"
                :target="`menu-item-${index}`"
            >
                <div v-for="(item1, index1) in item.items">
                    <button
                        :key="`menu-item-${index1}`"
                        :id="`menu-item-${index1}`"
                        @click="handleItemClick(item1)"
                    >
                        <div class="button-content">
                            <div class="icon" v-if="item1.icon">
                                <component :is="item1.icon" />
                            </div>
                            {{ $t(item1.title) }}
                        </div>
                        <div
                            v-if="item1.items"
                            :class="['icon-dropdown', open ? 'flipped' : '']"
                        >
                            <ArrowDown />
                        </div>
                    </button>
                </div>
            </b-popover>
        </div>
    </b-popover>
</template>

<script>
import HomeIcon from "@core/assets/icons/home-icon.svg";
import ArrowDown from "@core/assets/icons/arrow-down.svg";

import { BPopover } from "bootstrap-vue";
export default {
    name: "CollapsePopOver",
    components: { HomeIcon, BPopover, ArrowDown },
    props: {
        title: { type: String },
        items: {
            type: Array,
            required: true,
        },
        placement: {
            type: String,
            default: "right",
        },
        triggers: {
            type: String,
            default: "focus",
        },
        target: {
            type: String,
            required: true,
        },
        offset: {
            type: Number,
            default: 0,
        },
        customPosition: {
            type: String,
            default: null,
        },
    },
    data: () => {
        return { show: false, open: false, localTriggers: "focus" };
    },
    mounted() {
        this.localTriggers = this.triggers;
    },
    methods: {
        closePopover() {
            this.show = !this.show;
            this.$emit("close");
        },
        handleItemClick(item) {
            if (item.items) {
                this.open = !this.open;
            } else {
                item.click();
                this.show = false;
                this.$emit("close");
            }
        },
        hidePopover(e) {
            e.preventDefault();

            setTimeout(() => {
                this.open = false;
                this.show = false;
            }, 200);
        },
    },
};
</script>

<style lang="scss">
.navigation_popover {
    .arrow {
        display: none !important;
    }
    .popover-body {
        padding: 0 !important;
        border: none !important;
    }
    width: 180px !important;

    &.navbar {
        top: -3px !important;
        left: 40px !important;
    }

    &.sidebar {
        top: -20px !important;
    }
}

@media screen and (max-width: 375px) {
    .navigation_popover {
        &.child {
            left: -20px !important;
            width: 160px !important;
        }
    }
}

@media screen and (max-width: 320px) {
    .navigation_popover {
        &.child {
            left: -40px !important;
            width: 150px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.flipped {
    transform: rotate(270deg);
    transform-origin: center;
}
.icon-dropdown {
    width: 16px;
    height: 100%;
}
.icon {
    width: 16px;
    height: 16px;
    svg {
        transform: translate(-20%, 0%) scale(0.6668);
    }
}
.popover {
    display: flex;
    width: auto !important;
    padding: 8px 0px;
    border-radius: 6px;
    border: none;
    background: #fff;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    svg {
        fill: #998f8a;
    }
    header {
        padding: 12px 21px;
        font-size: 14px;
        font-weight: 600;
        color: #4c4541;
    }

    button {
        outline: 0;
        background: transparent;
        border: 0;
        cursor: pointer;

        width: 100%;
        padding: 12px 21px;

        display: flex;
        justify-content: space-between;

        user-select: none;

        .button-content {
            display: flex;
            gap: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #998f8a;
        }

        &:hover {
            background-color: #ffede2;
            .button-content {
                color: #974900;
                svg {
                    fill: #974900;
                }
            }
        }

        &:active {
            background-color: #ffdbc4;
        }
        &.selected {
            background: #974900;
        }
    }
}
</style>
