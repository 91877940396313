<template>
  <b-tooltip
    :target="target"
    :triggers="triggers"
    :placement="placement"
    :custom-class="getString()"
  >
    {{ text }}
  </b-tooltip>
</template>

<script>
  import { BTooltip } from 'bootstrap-vue';
  export default {
    name: 'TooltipNavbar',
    components: { BTooltip },
    props: {
      target: {
        type: String,
        required: true
      },
      triggers: {
        type: String,
        default: 'hover'
      },
      placement: {
        type: String,
        default: 'right'
      },
      text: {
        type: String,
        required: true
      }
    },
    methods: {
      getString() {
        let customClass = 'nav-tooltip ';

        switch (this.placement) {
        case 'top':
          customClass += 'place-top';
          break;
        case 'right':
          customClass += 'place-right';
          break;
        case 'bottom':
          customClass += 'place-bottom';
          break;
        case 'left':
          customClass += 'place-left';
          break;
        default:
          break;
        }

        return customClass;
      }
    }
  };
</script>

<style lang="scss">
  .nav-tooltip {
    margin-left: 4px !important;
    .tooltip-inner {
      padding: 8px !important;
      border-radius: 6px !important;
      background: #362f2b !important;
      color: var(--colors-text-white-primary-100, #fff) !important;
      font-family: 'Raleway', sans-serif !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
    }

    &.place-top {
      margin-bottom: 8px;

      .arrow::before {
        border-top-color: #362f2b !important;
      }
    }

    &.place-right {
      margin-left: 8px;

      .arrow::before {
        border-right-color: #362f2b !important;
      }
    }

    &.place-bottom {
      margin-bottom: 8px;

      .arrow::before {
        border-bottom-color: #362f2b !important;
      }
    }

    &.place-left {
      margin-right: 8px;

      .arrow::before {
        border-left-color: #362f2b !important;
      }
    }
  }
</style>
