<template>
  <div id="navbar" :class="{ 'remove-shadow': operatorMode }">
    <div class="side">
      <div
        class="toggle"
        @click="openFullscreenMenu"
        v-show="!operatorMode || device !== 'desktop'"
      >
        <HamburgerIcon class="hamburger-icon" />
      </div>
      <div class="logo">
        <Logo class="logo-icon" v-if="device == 'mobile'" />
        <LogoFull class="logo-icon" v-else />
      </div>
    </div>

    <div class="nav-items" v-if="operatorMode && device !== 'mobile'">
      <NavItem
        item-id="myind-navbar"
        :name="$t('Menu.Items.MyInd')"
        variant="short"
        :icon="icons.MyIndIcon"
        @click="() => redirect('myind')"
        tooltipPlacement="bottom"
      />

      <NavItem
        :name="$t('PCP Mode')"
        v-if="pcpAccess"
        variant="short"
        :icon="icons.PCPMode"
        @click="() => toggleOperator()"
        tooltipPlacement="bottom"
      />

      <NavItem
        :name="$t('Operator.Navigation.Title')"
        variant="short"
        :icon="icons.OperatorNavigationMenuIcon"
        @click="() => toggleNavigationMenu()"
        tooltipPlacement="bottom"
      />
    </div>

    <OperatorNavigationMenu :open="isMenuOpen" @close="toggleNavigationMenu" />

    <SiteSelector v-show="!operatorMode" :device="device" origin="navbar" />

    <div id="profile_trigger_navbar" class="profile" tabindex="0">
      <div class="user">
        <h3>{{ user.first_name }} {{ user.last_name }}</h3>
        <h2>{{ user.job_role }}</h2>
      </div>
      <div class="picture">
        <img v-if="user.photo" :src="user.photo" alt="User photo" />
        <AvatarPlaceholder v-else size="38px" :text="picturePlaceholder" />
      </div>
      <div class="arrow-down">
        <ArrowDown class="arrow-down-icon" />
      </div>
    </div>

    <CollapsePopover
      :items="operatorMode && device !== 'mobile' ? items : mobileItems"
      target="profile_trigger_navbar"
      placement="down"
      custom-position="navbar"
    />

    <transition name="menumodal">
      <Menu
        v-if="open"
        :user="user"
        :device="device"
        :operatorMode="operatorMode"
        wide
        @logout="logout"
        @redirect="() => redirect('account-setting')"
        @close="closeFullscreenMenu"
        @toggleOperator="toggleOperator"
      />
    </transition>
  </div>
</template>

<script>
import Logo from "@core/assets/icons/logo-short.svg";
import LogoFull from "@core/assets/icons/logo-full.svg";
import HamburgerIcon from "@core/assets/icons/hamburger-icon.svg";
import ArrowDown from "@core/assets/icons/arrow-down.svg";
import ProfileIcon from "@core/assets/icons/profile-icon.svg";
import ExitIcon from "@core/assets/icons/exit-icon.svg";
import PCPMode from "@core/assets/icons/pcpmode.svg";
import Operator from "@core/assets/icons/operator.svg";
import MyIndIcon from "@core/assets/icons/myind.svg";
import OperatorNavigationMenuIcon from "@core/assets/icons/applications.svg";
import BrazilFlag from "@core/assets/icons/brazil-flag.svg";
import USAFlag from "@core/assets/icons/usa-flag.svg";
import LanguageIcon from "@core/assets/icons/language.svg";
import SwtichAccountIcon from "@core/assets/icons/switchaccount.svg";
import SupportIcon from "@core/assets/icons/headset-icon.svg";

import Menu from "../Sidebar/index.vue";
import CollapsePopover from "../CollapsePopover.vue";
import SiteSelector from "../SiteSelector/index.vue";
import NavItem from "../NavItem.vue";
import OperatorNavigationMenu from "../OperatorNavigationMenu/index.vue";

import AvatarPlaceholder from "@/@core/components/avatar-placeholder/index.vue";

const ICONS = {
  ProfileIcon,
  ExitIcon,
  PCPMode,
  Operator,
  MyIndIcon,
  OperatorNavigationMenuIcon,
  BrazilFlag,
  USAFlag,
  LanguageIcon,
  SwtichAccountIcon,
  SupportIcon,
};

export default {
  name: "NavbarApp",
  props: {
    user: {
      type: Object,
      default: { first_name: "", last_name: "", photo: "" },
    },
    device: {
      type: String,
      default: "desktop",
    },
    operatorMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    picturePlaceholder() {
      const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? "";
      return `${firstLetter(this.user.first_name)}${firstLetter(
        this.user.last_name
      )}`;
    },
  },
  components: {
    Logo,
    LogoFull,
    HamburgerIcon,
    Menu,
    ArrowDown,
    CollapsePopover,
    SiteSelector,
    PCPMode,
    Operator,
    NavItem,
    MyIndIcon,
    OperatorNavigationMenu,
    OperatorNavigationMenuIcon,
    BrazilFlag,
    USAFlag,
    LanguageIcon,
    SwtichAccountIcon,
    SupportIcon,
    AvatarPlaceholder,
  },
  data() {
    var self = this;
    return {
      open: false,
      icons: ICONS,
      isMenuOpen: false,
      pcpAccess: true,
      mobileItems: [
        {
          title: "Menu.Items.Account",
          icon: ICONS.ProfileIcon,
          click: () =>
            (window.location.href = `${this.$myIndLink}${localStorage.getItem(
              "prefix"
            )}/account`),
        },
        {
          title: "ChangeAccount",
          icon: ICONS.SwtichAccountIcon,
          click: () => self.redirect("change-account"),
        },
        {
          title: "Support",
          icon: ICONS.SupportIcon,
          click: () =>
            (window.location.href = `${this.$myIndLink}${localStorage.getItem(
              "prefix"
            )}/support`),
        },
        {
          title: "Menu.Languages.Title",
          icon: ICONS.LanguageIcon,
          items: [
            {
              title: "Menu.Languages.English",
              icon: ICONS.USAFlag,
              click: () => this.changeLanguage("en-US"),
            },
            {
              title: "Menu.Languages.Portuguese",
              icon: ICONS.BrazilFlag,
              click: () => this.changeLanguage("pt-BR"),
            },
          ],
        },
        {
          title: "Menu.Items.Exit",
          icon: ICONS.ExitIcon,
          click: () => self.logout(),
        },
      ],
      items: [
        {
          title: "Menu.Items.Account",
          icon: ICONS.ProfileIcon,
          click: () =>
            (window.location.href = `${this.$myIndLink}${localStorage.getItem(
              "prefix"
            )}/account`),
        },
        {
          title: "ChangeAccount",
          icon: ICONS.SwtichAccountIcon,
          click: () => self.redirect("change-account"),
        },
        {
          title: "Support",
          icon: ICONS.SupportIcon,
          click: () =>
            (window.location.href = `${this.$myIndLink}${localStorage.getItem(
              "prefix"
            )}/support`),
        },
        {
          title: "Menu.Languages.Title",
          icon: ICONS.LanguageIcon,
          items: [
            {
              title: "Menu.Languages.English",
              icon: ICONS.USAFlag,
              click: () => this.changeLanguage("en-US"),
            },
            {
              title: "Menu.Languages.Portuguese",
              icon: ICONS.BrazilFlag,
              click: () => this.changeLanguage("pt-BR"),
            },
          ],
        },
        {
          title: "Menu.Items.Exit",
          icon: ICONS.ExitIcon,
          click: () => self.logout(),
        },
      ],
    };
  },
  methods: {
    changeLanguage(slug) {
      this.$i18n.locale = slug;
      localStorage.setItem("language", slug);
    },
    verifyPCPAccess() {
      let userPCPAccess = this.$cookies.get("userInfo").userData.pcp_access;
      userPCPAccess === "0"
        ? (this.pcpAccess = false)
        : (this.pcpAccess = true);
    },
    toggleNavigationMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleOperator() {
      this.$emit("toggleOperator");
    },
    logout() {
      this.$emit("logout");
    },
    openFullscreenMenu() {
      this.open = true;
    },
    closeFullscreenMenu() {
      this.open = false;
    },
    redirect(page) {
      if (this.$route.name === page) return;
      if (page == "myind") {
        window.location.href = this.$myIndLink;
        return;
      }

      this.$router.push({ name: page });
    },
  },
  created() {
    this.verifyPCPAccess();
  },
};
</script>

<style lang="scss">
.menumodal-enter-active,
.menumodal-leave-active {
  transition: all 0.5s;
  margin-left: 0px !important;
}

.menumodal-enter,
.menumodal-leave-to {
  margin-left: calc(-100vw) !important;
}
</style>

<style lang="scss" scoped>
#navbar {
  width: 100%;
  height: 60px;
  padding: 0 29px;
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);
  align-items: center;

  &.remove-shadow {
    box-shadow: none;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    z-index: -1;
  }
}

.nav-items {
  display: flex;
  padding-right: 14px;
}

.side {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  gap: 20px;

  .toggle {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 100%;
    svg {
      fill: #4c4541;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 28px;
    height: 100%;
  }
}

.profile {
  cursor: pointer;
  border-left: 1px solid #eee;
  height: 100%;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 243px;
  min-width: 0;

  .user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: flex-start;
    max-width: 100%;
    min-width: 0px;

    h3,
    h2 {
      margin: 0;
      padding: 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    h3 {
      color: #4c4541;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    h2 {
      color: #998f8a;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: right;
      width: -webkit-fill-available;
    }
  }

  .picture {
    height: 100%;
    display: flex;
    align-items: center;
  }

  img {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 100%;
  }

  .arrow-down {
    height: 100%;
    width: 16px;
    display: flex;
    align-items: center;
    svg {
      fill: #998f8a;
    }
  }
}

.hamburger-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.logo-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-down-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 575px) {
  #navbar {
    padding: 0 16px !important;
  }
}

@media (max-width: 425px) {
  .user > h2,
  h3 {
    max-width: 102px !important;
  }
}
</style>
