<template>
    <div>
        <div
            id="menu-bar"
            :class="[isOpen ? 'open' : '', wide ? 'wide' : '']"
            ref="sidebar"
        >
            <!-- Logo -->
            <div :class="['logo-container', isOpen ? 'logo-full' : 'logo']">
                <LogoFull v-if="isOpen" class="logo-full-icon" />
                <LogoShort v-else class="logo-short-icon" />
                <button
                    v-if="wide"
                    class="menu-full-close-btn"
                    @click="$emit('close')"
                >
                    <CloseIcon
                        class="close-icon"
                        :style="{
                            width: '12px !important',
                            height: '12px !important',
                        }"
                    />
                </button>
            </div>
            <!-- User information -->
            <div class="user-info">
                <Profile
                    :variant="variant"
                    :user="user"
                    :title="$t('Menu.Titles.Account')"
                    @close="$emit('close')"
                    @logout="$emit('logout')"
                />
            </div>

            <!-- General -->
            <NavItemGroup
                ref="generalGroup"
                :title="$t('Menu.Groups.General')"
                :variant="variant"
            >
                <NavItem
                    :name="$t('Menu.Items.MyInd')"
                    :variant="variant"
                    :selected="currentPage.includes('myind')"
                    :icon="icons.MyIndIcon"
                    @click="() => redirect('myind')"
                    item-id="myind-sidebar"
                    data-redirect="home"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'home'
                              )
                    "
                />

                <LanguageSelector :variant="variant" />
            </NavItemGroup>

            <!-- Funcionalities -->
            <NavItemGroup
                ref="funcionalitiesGroup"
                :title="$t('Menu.Groups.Funcionalities')"
                :variant="variant"
            >
                <NavItem
                    :name="operatorMode ? $t('PCP Mode') : $t('Operator Mode')"
                    :variant="variant"
                    :icon="operatorMode ? icons.PCPMode : icons.Operator"
                    @click="() => toggleOperator()"
                    data-redirect="operator"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'operator'
                              )
                    "
                />
                <NavItem
                    :name="$t('Orders')"
                    :variant="variant"
                    :selected="currentPage.includes('/order')"
                    :icon="icons.TotalOrders"
                    @click="
                        () =>
                            redirect(
                                operatorMode
                                    ? 'operator-orders-all'
                                    : 'order-list'
                            )
                    "
                    :data-redirect="
                        operatorMode ? 'operator-orders-all' : 'order-list'
                    "
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find((item) =>
                                  [
                                      'operator-orders-all',
                                      'order-list',
                                  ].includes(item.id)
                              )
                    "
                />

                <NavItem
                    v-if="operatorMode"
                    :name="$t('Operator.BarCodeTitle')"
                    :variant="variant"
                    :selected="currentPage.includes('/barcode')"
                    :icon="icons.BarcodeIcon"
                    @click="() => redirect('operator-barcode')"
                    data-redirect="operator-barcode"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'operator-barcode'
                              )
                    "
                />

                <NavItem
                    v-if="!operatorMode"
                    :name="$t('Menu.Items.Appointments')"
                    :variant="variant"
                    :selected="currentPage.includes('/production-progress')"
                    :icon="icons.OperationProgress"
                    @click="() => redirect('production-progress')"
                    data-redirect="production-progress"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'production-progress'
                              )
                    "
                />

                <CollapseNavItem
                    v-if="!operatorMode"
                    :name="$t('Dashboards')"
                    :items="dashboardItems"
                    :variant="variant"
                    :icon="icons.DashboardIcon"
                    data-redirect="dashboards"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'dashboards'
                              )
                    "
                />

                <CollapseNavItem
                    v-if="!operatorMode"
                    :name="$t('Settings')"
                    :items="settingsItems"
                    :variant="variant"
                    :icon="icons.SettingsIcon"
                    data-redirect="settings"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'settings'
                              )
                    "
                />
            </NavItemGroup>

            <!-- Help -->
            <NavItemGroup
                ref="helpGroup"
                :title="$t('Menu.Groups.Help')"
                :border="false"
                :variant="variant"
                v-show="
                    isOpen
                        ? true
                        : !overflowNavItems.find((item) => item.id == 'support')
                "
            >
                <NavItem
                    :name="$t('Menu.Items.Support')"
                    :variant="variant"
                    :selected="currentPage.includes('/support')"
                    :icon="icons.HeadsetIcon"
                    @click="() => redirect('support')"
                    data-redirect="support"
                    v-show="
                        isOpen
                            ? true
                            : !overflowNavItems.find(
                                  (item) => item.id == 'support'
                              )
                    "
                />
            </NavItemGroup>

            <Extra
                :items="overflowNavItems"
                :variant="variant"
                v-show="overflowNavItems.length"
            />

            <!-- Sites -->
            <SiteSelector
                :variant="variant"
                :device="device"
                origin="sidebar"
            />
        </div>
        <!-- Toggle Sidebar Button -->
        <button v-if="!wide" class="toggle-sidebar" @click="toggleSidebar">
            <span v-if="isOpen">
                <ArrowLeft class="toggle-icon" />
            </span>
            <span v-else>
                <ArrowRight class="toggle-icon" />
            </span>
        </button>
    </div>
</template>

<script>
// Icons
import ArrowLeft from "@core/assets/icons/arrow-left.svg";
import ArrowRight from "@core/assets/icons/arrow-right.svg";
import LogoFull from "@core/assets/icons/logo-full.svg";
import LogoShort from "@core/assets/icons/logo-short.svg";
import HeadsetIcon from "@core/assets/icons/headset-icon.svg";
import CloseIcon from "@core/assets/icons/x-icon.svg";
import MyIndIcon from "@core/assets/icons/myind.svg";
import DashboardIcon from "@core/assets/icons/dashboard.svg";
import ExtraIcon from "@core/assets/icons/extra.svg";
import Operator from "@core/assets/icons/operator.svg";
import TotalOrders from "@core/assets/icons/totalorders.svg";
import OperationProgress from "@core/assets/icons/operationprogress.svg";
import SettingsIcon from "@core/assets/icons/settings.svg";
import OEEIcon from "@core/assets/icons/OEE.svg";
import Resources from "@core/assets/icons/resources.svg";
import Capacity from "@core/assets/icons/capacity.svg";
import Database from "@/assets/images/pages/database-navbar-icon.svg";
import List from "@core/assets/icons/list.svg";
import People from "@core/assets/icons/people.svg";
import PrimaryResources from "@core/assets/icons/primaryresources.svg";
import PCPMode from "@core/assets/icons/pcpmode.svg";
import BarcodeIcon from "@core/assets/icons/barcode.svg";

// Components
import Profile from "../Profile/index.vue";
import NavItem from "../NavItem.vue";
import NavItemGroup from "../NavItemGroup.vue";
import SiteSelector from "../SiteSelector/index.vue";
import LanguageSelector from "../LanguageSelector/index.vue";
import Extra from "../Extra/index.vue";
import CollapseNavItem from "../CollapseNavItem.vue";
import { mapGetters, mapMutations } from "vuex";

const ICONS = {
    HeadsetIcon,
    MyIndIcon,
    DashboardIcon,
    ExtraIcon,
    Operator,
    TotalOrders,
    OperationProgress,
    SettingsIcon,
    OEEIcon,
    Resources,
    Capacity,
    Database,
    List,
    People,
    PrimaryResources,
    PCPMode,
    BarcodeIcon,
};

export default {
    name: "MenuBar",
    components: {
        // Icons
        ArrowLeft,
        ArrowRight,
        LogoFull,
        LogoShort,
        CloseIcon,
        MyIndIcon,
        DashboardIcon,
        ExtraIcon,
        Operator,
        TotalOrders,
        OperationProgress,
        SettingsIcon,
        OEEIcon,
        Resources,
        Capacity,
        Database,
        List,
        People,
        PrimaryResources,
        PCPMode,
        BarcodeIcon,

        // Components
        Profile,
        NavItem,
        NavItemGroup,
        SiteSelector,
        LanguageSelector,
        Extra,
        CollapseNavItem,
    },
    props: {
        wide: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: { first_name: "", last_name: "", photo: "" },
        },
        device: {
            type: String,
            default: "desktop",
        },
        operatorMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: this?.wide ? true : false,
            icons: ICONS,
            prefix: this.$router.currentRoute.params.prefix,
            visibleNavItems: [],
            overflowNavItems: [],
            settingsItems: [
                {
                    title: "WorkCenters",
                    icon: ICONS.People,
                    click: () => this.redirect("workcenters"),
                    slug: "workcenters",
                },
                {
                    title: "Classification",
                    icon: ICONS.List,
                    slug: "workcenters",
                    items: [
                        {
                            title: "Refuse Status",
                            click: () => this.redirect("refuse-status"),
                            slug: "refuse-status",
                        },
                        {
                            title: "Resource Status",
                            click: () => this.redirect("resource-status"),
                            slug: "resource-status",
                        },
                    ],
                },
                {
                    title: "Primary Resources",
                    icon: ICONS.PrimaryResources,
                    click: () => this.redirect("resources"),
                    slug: "resources",
                },
                {
                    title: "Sites",
                    icon: ICONS.Database,
                    click: () => this.redirect("sites"),
                    slug: "sites",
                },
            ],
            dashboardItems: [
                {
                    title: "Resource Loading",
                    icon: ICONS.Resources,
                    click: () => this.redirect("resource-loading"),
                    slug: "resource-loading",
                },
                {
                    title: "Schedule Analysis",
                    icon: ICONS.Capacity,
                    click: () => this.redirect("schedule-analysis"),
                    slug: "schedule-analysis",
                },
                {
                    title: "OEE",
                    icon: ICONS.OEEIcon,
                    click: () => this.redirect("oee"),
                    slug: "oee",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("app", [
            "flagMenuOpen",
        ]),
        variant() {
            return this.isOpen ? "full" : "short";
        },
        currentPage() {
            return this.$route.path;
        },
        language() {
            return this.$i18n.locale;
        },
    },
    mounted() {
        this.addVisibleNavItems();
    },
    created() {
        window.addEventListener("resize", this.addVisibleNavItems);
    },
    destroyed() {
        window.removeEventListener("resize", this.addVisibleNavItems);
    },
    methods: {
        ...mapMutations("app", [
            "UPDATE_FLAG_MENU_OPEN",
        ]),
        toggleOperator() {
            this.$emit("toggleOperator");
            if (this.wide) {
                this.$emit("close");
            }
        },
        toggleSidebar() {
            this.isOpen = !this.isOpen;
            this.UPDATE_FLAG_MENU_OPEN(this.isOpen);
        },
        redirect(page) {
            if (this.$route.name === page) return;
            if (page == "myind") {
                window.location.href = this.$myIndLink;
                return;
            }

            let tenant = this.user.email.split("@");
            this.prefix = this.prefix ? this.prefix : tenant[1].split(".")[0];

            if (page === "support") {
                window.location.href = `${this.$myIndLink}${this.prefix}/support`;
                return;
            }

            this.$router.push({ name: page, params: { prefix: this.prefix } });

            if (this.wide) {
                this.$emit("close");
            }
        },
        addVisibleNavItems() {
            const sidebar = this.$refs.sidebar;
            const navItemGroups = [
                this.$refs.generalGroup,
                this.$refs.funcionalitiesGroup,
                this.$refs.helpGroup,
            ];

            // Tamanho da viewport
            const viewportHeight = window.innerHeight - 100;
            // Posição do topo do sidebar em relação ao documento
            const sidebarTop = sidebar.getBoundingClientRect().top;
            // Itens visíveis e itens que ultrapassam o limite da viewport
            const visibleNavItems = [];
            const overflowNavItems = [];

            for (const group of navItemGroups) {
                const groupTitle = group.title;
                const navItems = group.$children;
                for (const item of navItems) {
                    const itemRect = item.$el.getBoundingClientRect();
                    const itemTop = itemRect.top - sidebarTop;
                    if (
                        itemTop >= 0 &&
                        itemTop + itemRect.height <= viewportHeight
                    ) {
                        // O item está visível na viewport
                        visibleNavItems.push({
                            group: groupTitle,
                            navItem: item,
                        });
                    } else {
                        // O item ultrapassa o limite da viewport
                        overflowNavItems.push({
                            group: groupTitle,
                            title: item.name,
                            id: item.$el.dataset.redirect,
                            icon: item.icon,
                            items: item.items || [],
                            click: () =>
                                this.redirect(item.$el.dataset.redirect),
                        });
                    }
                }
            }

            // Atualizar a lista de itens visíveis e itens que ultrapassam a viewport
            this.visibleNavItems = visibleNavItems;
            this.overflowNavItems = overflowNavItems;
        },
    },
};
</script>

<style lang="scss" scoped>
.side-items {
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.close-icon {
    width: 12px;
    height: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-full-close-btn {
    outline: 0;
    border: 1px solid transparent;
    background: transparent;
    cursor: pointer;

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    border: 1px solid #cfc4be;
    background: #fff;

    svg {
        fill: #4c4541;
    }

    &:hover {
        border: 1px solid #974900;
    }

    &:active {
        border: 1px solid #e1730b;
    }
}
.toggle-icon {
    width: 10px;
    height: 10px;
}

#menu-bar {
    width: 98px;
    height: 100vh;
    padding: 16px 0;

    transition: all 0.6s;

    background-color: #fff;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.open {
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        padding-bottom: 60px;
    }
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-full {
    padding: 23px 16px;
    text-align: left;
}

.user-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
}

.toggle-sidebar {
    width: 24px;
    height: 24px;

    padding: 8px;

    justify-content: center;
    align-items: center;
    gap: 10px;
    outline: 0;

    position: absolute;
    right: -12px;
    top: 24px;

    border-radius: 50%;
    border: 1px solid #cfc4be;
    background-color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: #4c4541;
    }

    &:hover {
        border: 1px solid #974900;
        background-color: #ffede2;
        svg {
            path {
                fill: #4c4541;
            }
        }
    }

    &:active {
        background-color: #ffdbc4;
    }

    span {
        color: white;
    }

    cursor: pointer;
}

.open {
    width: 260px !important;
    gap: 12px !important;
}

.wide {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 5 !important;
    width: 100vw !important;
    overflow-y: auto !important;
    background-color: #fff !important;
}
</style>
