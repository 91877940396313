<template>
    <div>
        <transition appear name="operator">
            <div v-if="open" class="operator-navigation">
                <div class="operation-navigation-ibox">
                    <Navigation @close="close" />
                    <Sites />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import OperatorNavigationMenuIcon from "@/assets/images/pages/operator-navigation-menu-icon.svg";
import Navigation from "./Navigation/index.vue";
import Sites from "./Sites/index.vue";

export default {
    components: {
        OperatorNavigationMenuIcon,
        Sites,
        Navigation,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss">
.operator-navigation {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 2;
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.4);

    .operation-navigation-ibox {
        width: 100%;
        background-color: white;
        margin: 0;

        display: flex;
        flex-direction: row;
        border-top: 1px solid #cfc4be;
        padding: 0;
        overflow: hidden;
    }
}
#operator-navigation-menu-button {
    padding: 6px 12px;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 14px;
    outline: 0;
    border: 0;

    width: 44px;
    height: 38px;

    background: transparent;
    &:hover {
        background: #ffdbc4;

        svg path {
            fill: #974900;
        }
    }
}

.operator-enter,
.operator-leave-to {
    opacity: 0%;
    .operation-navigation-ibox {
        max-height: 0;
    }
}

.operator-enter-to,
.operator-leave {
    opacity: 100%;
    .operation-navigation-ibox {
        max-height: 300px;
    }
}

.operator-enter-active,
.operator-leave-active {
    transition: all 0.25s ease-in-out;
    .operation-navigation-ibox {
        transition: all 0.25s ease-in-out;
    }
}
</style>
