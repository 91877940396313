<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ $t("Footer.Copyright") }} {{ new Date().getFullYear() }}
      <span class="indsiders">{{ $t("Footer.Indsiders") }}</span
      >.
      <span v-show="showAllRightsReserved">{{
        $t("Footer.AllRightsReserved")
      }}</span>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  data: () => ({
    showAllRightsReserved: window.innerWidth > 540,
  }),
  beforeMount() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.showAllRightsReserved = window.innerWidth > 540;
    },
  },
};
</script>

<style lang="scss" scoped>
.clearfix.mb-0 {
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4c4541;
  }
  .indsiders {
    color: #ff8d2e;
  }
}
</style>
