<template>
    <div v-if="variant == 'short'">
        <NavItem
            :id="'settings-target-' + name"
            :name="name"
            :selected="checkSelected()"
            :border="false"
            :variant="variant"
            :icon="icon"
            tabindex="0"
        >
        </NavItem>
        <Tooltip :text="name" :target="'settings-target-' + name" />
        <CollapsePopover
            :title="$t(name)"
            :items="items"
            :target="'settings-target-' + name"
            placement="rightbottom"
        />
    </div>

    <div v-else class="settings-list">
        <div class="nav-item-full" @click="open = !open">
            <div class="icon-full">
                <component :is="icon" />
            </div>
            <span>{{ name }}</span>
            <div :class="['icon-dropdown', open ? 'flipped' : '']">
                <ArrowRight />
            </div>
        </div>
        <transition name="navgroup">
            <div v-if="open" class="items">
                <div v-for="item in items">
                    <CollapseNavItemComponent
                        v-if="item.items"
                        :name="$t(item.title)"
                        :items="item.items"
                        :variant="variant"
                        :ident="true"
                    />
                    <NavItem
                        v-else
                        :id="'settings-target-' + name"
                        :key="'key-' + item.title"
                        :name="$t(item.title)"
                        :selected="currentPage.includes(item.slug)"
                        :border="false"
                        :variant="variant"
                        @click="item.click()"
                        :ident="ident"
                    >
                    </NavItem>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import NavItem from "./NavItem.vue";
import NavItemGroup from "./NavItemGroup.vue";
import CollapsePopover from "./CollapsePopover.vue";
import Tooltip from "./Tooltip.vue";

import ArrowRight from "@core/assets/icons/arrow-right.svg";

export default {
    name: "CollapseNavItemComponent",
    props: {
        variant: { type: String, default: "short" },
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: Object,
        },
        ident: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NavItem,
        NavItemGroup,
        CollapsePopover,
        Tooltip,
        ArrowRight,
    },
    methods: {
        checkSelected() {
            let flag = false;
            this.items.forEach((item) => {
                if (this.currentPage.includes(item.slug)) flag = true;
                if (item.items) {
                    item.items.forEach((subitem) => {
                        if (this.currentPage.includes(subitem.slug))
                            flag = true;
                    });
                }
            });
            return flag;
        },
    },
    computed: {
        currentPage() {
            return this.$route.path;
        },
    },
    data() {
        return {
            open: false,
        };
    },
};
</script>
<style lang="scss" scoped>
.flipped {
    transform: rotate(90deg);
    transform-origin: center;
}
.icon-full {
    width: 14px;
    height: 14px;

    svg {
        transform: translate(-20%, -10%) scale(0.5833);
        fill: #998f8a;
    }
}

.icon-dropdown {
    width: 16px;
    height: 100%;

    svg {
        fill: #998f8a;
    }
}

.nav-item-full {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 10px;
    line-height: 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    align-self: stretch;

    cursor: pointer;
    user-select: none;

    span {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex: 1;
        color: #998f8a;

        font-style: normal;
        font-weight: 400;
    }

    &:hover {
        background-color: #ffede2;
        svg {
            fill: #974900;
        }
        span {
            color: #974900;
        }
    }

    &:active {
        background-color: #ffdbc4;
        color: #974900;
    }

    &.selected {
        background-color: #974900;
        svg {
            fill: #fff !important;
        }
        span {
            color: #fff;
        }
    }
}
</style>
