<template>
    <b-popover
        :custom-class="`navigation_popover ${customPosition}`"
        :triggers="triggers"
        :target="target"
        :placement="placement"
        :offset="offset"
        :show.sync="show"
        boundary="viewport"
    >
        <header v-if="title">{{ title }}</header>
        <button
            v-for="(item, index) in items"
            :key="`menu-item-${index}`"
            @click="handleItemClick(item)"
        >
            <div class="icon" v-if="item.icon">
                <component :is="item.icon" />
            </div>
            {{ $t(item.title) }}
        </button>
    </b-popover>
</template>

<script>
import HomeIcon from "@core/assets/icons/home-icon.svg";
import { BPopover } from "bootstrap-vue";
export default {
    name: "PopOver",
    components: { HomeIcon, BPopover },
    props: {
        title: { type: String },
        items: {
            type: Array,
            required: true,
        },
        placement: {
            type: String,
            default: "right",
        },
        triggers: {
            type: String,
            default: "focus",
        },
        target: {
            type: String,
            required: true,
        },
        offset: {
            type: Number,
            default: 0,
        },
        customPosition: {
            type: String,
            default: null,
        },
    },
    data: () => ({ show: false }),
    methods: {
        handleItemClick(item) {
            item.click();
            this.show = false;
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss">
.navigation_popover {
    .arrow {
        display: none !important;
    }
    .popover-body {
        padding: 0 !important;
        border: none !important;
    }
    width: 180px !important;

    &.navbar {
        top: -3px !important;
        left: 40px !important;
    }

    &.sidebar {
        top: -20px !important;
    }
}
</style>

<style lang="scss" scoped>
.icon {
    width: 16px;
    height: 16px;
    svg {
        transform: translate(-20%, 0%) scale(0.5833);
    }
}
.popover {
    display: flex;
    width: auto !important;
    padding: 8px 0px;
    border-radius: 6px;
    border: none;
    background: #fff;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    svg {
        fill: #998f8a;
    }
    header {
        padding: 12px 21px;
        font-size: 14px;
        font-weight: 600;
        color: #4c4541;
    }

    button {
        outline: 0;
        background: transparent;
        border: 0;
        cursor: pointer;

        width: 100%;
        padding: 12px 21px;

        display: flex;
        flex-direction: row;
        gap: 9px;

        font-size: 14px;
        font-weight: 400;
        color: #998f8a;

        user-select: none;

        &:hover {
            background-color: #ffede2;
            color: #974900;
        }

        &:active {
            background-color: #ffdbc4;
        }
        &.selected {
            background: #974900;
        }
    }
}
</style>
