<template>
    <!-- Full size variant -->
    <div
        v-if="variant === 'full'"
        :id="id"
        :class="['nav-item-full', open ? 'open' : '']"
        @click="handleClickFull"
    >
        <div class="content">
            <div class="icon-full">
                <slot name="icon" />
            </div>
            <span>{{ name }}</span>
        </div>
        <div v-if="dropdown" :class="['dropdown', open ? 'flipped' : '']">
            <div class="icon-dropdown">
                <ArrowRight />
            </div>
        </div>
    </div>

    <!-- Compacted size variant -->
    <div v-else :id="id" class="nav-item-short" @click="$emit('click')">
        <div class="icon-short">
            <slot name="icon" />
        </div>
    </div>
</template>

<script>
import ArrowRight from "@core/assets/icons/arrow-right.svg";
export default {
    components: { ArrowRight },
    props: {
        id: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            required: true,
        },
        dropdown: {
            type: Boolean,
            default: false,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClickFull() {
            this.$emit("update:open", !this.open);
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
.flipped {
    transform: rotate(90deg);
    transform-origin: center;
}
.icon-dropdown {
    width: 16px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: #998f8a;
    }
}

.icon-full {
    width: 14px;
    height: 14px;
    svg {
        transform: translate(-20%, -10%) scale(0.5833);
    }
}
.icon-short {
    width: 20px;
    height: 20px;
    svg {
        transform: scale(0.8335);
    }
}
.nav-item-full {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 8px;
    justify-content: space-between;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    cursor: pointer;
    user-select: none;

    span {
        font-family: Raleway;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex: 1;
        color: #998f8a;
    }

    &:hover {
        background-color: #ffede2;
        span {
            color: #974900;
        }

        .icon-dropdown svg {
            fill: #974900;
        }
    }

    &:active,
    &.open {
        background-color: #ffdbc4;
        span {
            color: #974900;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
}

.nav-item-short {
    padding: 6px 12px;
    border-radius: 5px;
    max-width: max-content;

    cursor: pointer;
    border: 1px solid transparent;

    min-width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    margin: 0 auto;

    &:hover {
        background-color: #ffede2;
        span {
            color: #974900;
        }
        .icon-dropdown svg {
            path {
                fill: #974900;
            }
        }
    }

    &:active,
    &.open {
        background-color: #ffdbc4;
        color: #974900;
    }

    &.selected {
        background: #974900;
    }
}
</style>
